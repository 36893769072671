import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoLarge.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.any,
};

export default function LogoLarge({ ...other }) {
  return <Box component="img" alt="logo" src="/static/brand/logo_single.svg" height={80} {...other} />;
}
