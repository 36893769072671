import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { forEach } from 'lodash';

import useAuth from '../hooks/useAuth';
import axios from '../utils/API';

// ----------------------------------------------------------------------

const initialState = {
  companies: [],
  projects: [],
  loading: true,
  currentCompany: '',
  indexersLoading: true,
  dashboard: {
    selectedProject: '-1',
    selectedProjectEvents: [],
  },
  retrievingLogs: false,
  events: {
    selectedProject: '-1',
    selectedProjectEvents: [],
  },
  webhookLogs: { events: [], totalPages: 0, currentPage: 0 },
  initError: false,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { currentCompany } = action.payload;

    return { ...state, currentCompany };
  },
  SET_LOADING: (state, action) => {
    return { ...state, ...action.payload };
  },
  DONE_LOADING: (state, action) => {
    return { ...state, loading: action.payload };
  },
  SET_COMPANY: (state, action) => {
    return { ...state, ...action.payload };
  },
  SET_COMPANIES: (state, action) => {
    return { ...state, ...action.payload };
  },
  SET_PROJECTS: (state, action) => {
    return { ...state, ...action.payload };
  },
  SET_DASHBOARD_PROJECT: (state, action) => {
    const dashboard = { ...state.dashboard, selectedProject: action.payload };
    return { ...state, dashboard };
  },
  SET_DASHBOARD_EVENTS: (state, action) => {
    const dashboard = { ...state.dashboard, selectedProjectEvents: action.payload };
    return { ...state, dashboard };
  },
  SET_RETRIEVING_LOGS: (state, action) => {
    return { ...state, retrievingLogs: action.payload };
  },
  SET_WEBHOOK_LOGS: (state, action) => {
    return { ...state, webhookLogs: action.payload };
  },
  SET_APP_INIT_ERROR: (state, action) => {
    return { ...state, initError: action.payload };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AppDataContext = createContext({
  ...initialState,
});

const AppDataProvider = ({ children }) => {
  const { dashboardAPIUrl } = useSelector((state) => state.enviroment);
  const { user, isAuthenticated, isInitialized } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!isAuthenticated && isInitialized) {
      dispatch({
        type: 'DONE_LOADING',
        payload: false,
      });
    }
  }, [isAuthenticated, isInitialized]);

  const alphabetizeCompanies = (companies) => {
    return companies.sort((a, b) => {
      if (a.company_name.toLowerCase() < b.company_name.toLowerCase()) return -1;
      if (a.company_name.toLowerCase() > b.company_name.toLowerCase()) return 1;
      return 0;
    });
  };

  useEffect(async () => {
    // Set the current company after the user has authed and state has updated
    // The default company will be the user's personal company

    if (user) {
      try {
        const { data: myCompanies } = await axios.get(
          `${dashboardAPIUrl}/api/user/${user['https://graffle.io/graffle_id']}/companies`
        );

        const orderedCompanies = alphabetizeCompanies(myCompanies);

        dispatch({
          type: 'SET_COMPANIES',
          payload: {
            companies: orderedCompanies,
          },
        });

        const previouslySelectedCompany = localStorage.getItem('cid');
        const currCompany = orderedCompanies.find((company) => company.company_id === previouslySelectedCompany);

        if (currCompany === undefined) {
          updateCurrentCompany(orderedCompanies[0].company_id);
        } else {
          updateCurrentCompany(previouslySelectedCompany);
        }
      } catch (e) {
       // console.log(e);
        dispatch({
          type: 'APP_INIT_ERROR',
          payload: true,
        });
      }

      dispatch({
        type: 'DONE_LOADING',
        payload: false,
      });
    }
  }, [user]);

  useEffect(async () => {
    // Get the projects for this company
    dispatch({
      type: 'SET_LOADING',
      payload: {
        indexersLoading: true,
      },
    });

    if (state.currentCompany) {
      const { data } = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/projects/`);

      dispatch({
        type: 'SET_PROJECTS',
        payload: {
          projects: data,
          indexersLoading: false,
        },
      });
    }
  }, [state.currentCompany]);

  useEffect(() => {
    if (state.currentCompany.length > 0) {
      if (state.companies.find((company) => state.currentCompany === company.company_id) === undefined) {
        dispatch({
          type: 'SET_COMPANY',
          payload: {
            // companies,
            currentCompany: state.companies[0].company_id,
          },
        });
      }
    }
  }, [state.companies, state.currentCompany]);

  useEffect(async () => {
    // Get the events for a selected project
    if (state.dashboard.selectedProject.length > 2) {
      const { data: events } = await axios.get(
        `${dashboardAPIUrl}/api/project/${state.dashboard.selectedProject}/events`
      );

      dispatch({
        type: 'SET_DASHBOARD_EVENTS',
        payload: events,
      });
    }
  }, [state.dashboard.selectedProject]);

  const getWebhookLogs = async ({
    rowsPerPage = 25,
    currentPage = 1,
    selectedProject = undefined,
    eventIndexerId = undefined,
    flowEventId = undefined,
    transactionId = undefined,
    success = undefined,
  }) => {
    dispatch({
      type: 'SET_RETRIEVING_LOGS',
      payload: true,
    });

    try {
      const result = await axios.get(
        `${dashboardAPIUrl}/api/webhooks/company/${state.currentCompany}/project/${selectedProject}?`,
        {
          params: {
            eventIndexerId,
            flowEventId,
            transactionId,
            success,
            page: currentPage,
            pageSize: rowsPerPage,
          },
        }
      );

      const data = result.data;

      dispatch({
        type: 'SET_WEBHOOK_LOGS',
        payload: { events: result.status === 200 ? [...data.events] : [], currentPage: data.currentPage, totalPages: data.totalPages },
      });
    } catch (e) {
    //  console.log(e);
    }

    dispatch({
      type: 'SET_RETRIEVING_LOGS',
      payload: false,
    });
  };

  const replayEvents = async (events) => {
    const { data } = await axios.post(`${dashboardAPIUrl}/api/webhooks/company/${state.currentCompany}/replay`, events);
  };

  const dashboardUpdateCurrentSelectedProject = (projectId) => {
    // Get the events associated with the projects
    dispatch({
      type: 'SET_DASHBOARD_PROJECT',
      payload: projectId,
    });
    // Get the indexers associated with the project
  };

  const updateCurrentCompany = (companyId) => {
    dispatch({
      type: 'SET_COMPANY',
      payload: {
        currentCompany: companyId,
      },
    });

    window.localStorage.setItem('cid', companyId);
  };

  const refreshCompanies = async () => {
    const { data: myCompanies } = await axios.get(
      `${dashboardAPIUrl}/api/user/${user['https://graffle.io/graffle_id']}/companies`
    );

    const orderedCompanies = alphabetizeCompanies(myCompanies);

    dispatch({
      type: 'SET_COMPANIES',
      payload: {
        companies: orderedCompanies,
      },
    });
  };

  const deleteCompany = async () => {
    try {
      const res = await axios.delete(`${dashboardAPIUrl}/api/company/${state.currentCompany}`);

      window.localStorage.setItem(
        'cid',
        [...state.companies].filter((comp) => comp.company_id !== state.currentCompany)[0].company_id
      );

      // refreshCompanies
      refreshCompanies();
    } catch (e) {
      return false;
    }

    return true;
  };

  const getRole = () => {
    if (state.currentCompany !== '') {
      return state.companies.find((company) => company.company_id === state.currentCompany).role;
    }

    return 0;
  };

  const refreshProjects = async () => {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        indexersLoading: true,
      },
    });
    const { data } = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/projects/`);

    dispatch({
      type: 'SET_PROJECTS',
      payload: {
        projects: data,
        indexersLoading: false,
      },
    });
  };

  const updateCompanyName = async (newName) => {
    const res = await axios.put(`${dashboardAPIUrl}/api/company/${state.currentCompany}/profile`, { name: newName });

    if (res.status === 204) {
      refreshCompanies();
      return true;
    }
    return false;
  };

  const createNewEventIndexer = async (payload, projectId) => {
    const res = await axios.post(`${dashboardAPIUrl}/api/projects/${projectId}/events`, payload);

    if (res.status === 204) {
      return true;
    }

    return false;
  };

  const updateExistingEventIndexer = async (payload, projectId, eventId) => {
    const res = await axios.put(
      `${dashboardAPIUrl}/api/${state.currentCompany}/projects/${projectId}/events/${eventId}`,
      payload
    );

    refreshProjects();

    return res.status === 204;
  };

  const deleteIndexer = async (projectid, eventId) => {
    const res = await axios.delete(
      `${dashboardAPIUrl}/api/${state.currentCompany}/projects/${projectid}/events/${eventId}`
    );

    refreshProjects();

    return res.status === 204;
  };

  const getEventSettings = async (projectId, eventId) => {
    const { data } = await axios.get(`${dashboardAPIUrl}/api/projects/${projectId}/events/${eventId}`);

    return data;
  };

  const getProjectDetails = async (projectid) => {
    const { data } = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/projects/${projectid}`);

    return data;
  };

  const updateProjectDetails = async (payload, projectid) => {
    const res = await axios.put(
      `${dashboardAPIUrl}/api/company/${state.currentCompany}/projects/${projectid}`,
      payload
    );

    if (res.status === 204) {
      refreshProjects();
      return true;
    }

    return false;
  };

  const getWebhookLogDetails = async (projectid, webhooklogid) => {
    const { data } = await axios.get(
      `${dashboardAPIUrl}/api/webhooks/company/${state.currentCompany}/project/${projectid}/webHook/${webhooklogid}`
    );

    return data;
  };

  const deleteProject = async (projectId) => {
    const res = await axios.delete(`${dashboardAPIUrl}/api/company/${state.currentCompany}/projects/${projectId}`);

    if (res.status === 200) {
      refreshProjects();
      return res.data;
    }

    return false;
  };

  const craeteNewCompany = async (companyName) => {
    const result = await axios.post(`${dashboardAPIUrl}/api/company/${companyName}`);

    if (result.status === 200) {
      refreshCompanies();
      return true;
    }

    return false;
  };

  const createNewProject = async (projectInfo) => {
    const result = await axios.post(`${dashboardAPIUrl}/api/company/${state.currentCompany}/projects`, projectInfo);

    if (result.status === 201) {
      refreshProjects();
      return true;
    }
    return false;
  };

  const createFlowHealthSignalStream = async (url) => {
    const result = await axios.post(`${url}/negotiateHealthStream`);

    return result;
  };

  const createInvite = async (inviteData) => {
    const result = await axios.post(`${dashboardAPIUrl}/api/company/${state.currentCompany}/invites`, inviteData);

    return result.status === 200 ? result : false;
  };

  const getCanceledInvites = async () => {
    const result = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/invites/cancelled`);

    return result.status === 200 ? result : false;
  };

  const getCompanyMembers = async () => {
    const res = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/users`);

    return res.status === 200 ? res.data : false;
  };

  const removeMemberFromCompany = async (userId) => {
    const res = axios.delete(`${dashboardAPIUrl}/api/company/${state.currentCompany}/users/${userId}`);

    return res.status === 200;
  };

  const makeMemberAdmin = async (userId) => {
    const res = await axios.put(`${dashboardAPIUrl}/api/company/${state.currentCompany}/admins/${userId}`);

    return res.status === 200;
  };

  const revokeMemberAdmin = async (userId) => {
    const res = await axios.delete(`${dashboardAPIUrl}/api/company/${state.currentCompany}/admins/${userId}`);

    return res.status === 200;
  };

  const getPendingInvites = async () => {
    const result = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/invites/pending`);
    return result.status === 200 ? result.data : [];
  };

  const cancelInvite = async (inviteId) => {
    const res = await axios.put(`${dashboardAPIUrl}/api/company/${state.currentCompany}/invites/cancel/${inviteId}`);

    return res.status === 204;
  };

  const getProjects = async () => {
    const res = await axios.get(`${dashboardAPIUrl}/api/company/${state.currentCompany}/projects/`);

    return res.status === 200 ? res.data : [];
  };

  const createNewIndexer = async (indexerData, projectId) => {
    const res = await axios.post(
      `${dashboardAPIUrl}/api/${state.currentCompany}/projects/${projectId}/events`,
      indexerData
    );

    refreshProjects();

    if (res.status === 200) {
      return true;
    }

    return false;
  };

  const getWebHookUsage = async (companiesArray, startDate, endDate) => {
    const promises = companiesArray.map((company, i) =>
      axios.get(`${dashboardAPIUrl}/api/company/${company}/webHookUsage`, {
        params: {
          period: 0,
          startDate,
          endDate,
        },
      })
    );

    const test = await Promise.all(promises);

    const temp = test.reduce(
      (prev, curr) => ({ ...prev, [curr.request.responseURL.split('/api/company/')[1].split('/')[0]]: curr.data }),
      {}
    );

    return temp;
  };

  const getEventIndexerUsage = async (companiesArray, startDate, endDate) => {
    const promises = companiesArray.map((company, i) =>
      axios.get(`${dashboardAPIUrl}/api/company/${company}/eventIndexerUsage`, {
        params: {
          period: 0,
          startDate,
          endDate,
        },
      })
    );

    const test = await Promise.all(promises);

    const temp = test.reduce(
      (prev, curr) => ({ ...prev, [curr.request.responseURL.split('/api/company/')[1].split('/')[0]]: curr.data }),
      {}
    );

    return temp;
  };

  const getLiveStreamUsage = async (companiesArray, startDate, endDate) => {
    const promises = companiesArray.map((company, i) =>
      axios.get(`${dashboardAPIUrl}/api/company/${company}/liveStreamUsage`, {
        params: {
          period: 0,
          startDate,
          endDate,
        },
      })
    );

    const test = await Promise.all(promises);

    const temp = test.reduce(
      (prev, curr) => ({ ...prev, [curr.request.responseURL.split('/api/company/')[1].split('/')[0]]: curr.data }),
      {}
    );

    return temp;
  };

  const getSearchAPIUsage = async (companiesArray, startDate, endDate) => {
    const promises = companiesArray.map((company, i) =>
      axios.get(`${dashboardAPIUrl}/api/company/${company}/searchAPIUsage`, {
        params: {
          period: 0,
          startDate,
          endDate,
        },
      })
    );

    const test = await Promise.all(promises);

    const temp = test.reduce(
      (prev, curr) => ({ ...prev, [curr.request.responseURL.split('/api/company/')[1].split('/')[0]]: curr.data }),
      {}
    );

    return temp;
  };

  const removeUserFromCompany = async (companyId, userId) => {
    const res = await axios.delete(`${dashboardAPIUrl}/api/company/${companyId}/users/${userId}`);

    if (res.status === 204) {
      refreshCompanies();
      return true;
    }

    return false;
  };

  const acceptInvite = async (companyId, inviteId) => {
    const res = await axios.post(`${dashboardAPIUrl}/api/company/${companyId}/invites/accept/${inviteId}`);

    if (res.status === 204) {
      return true;
    }

    return false;
  };

  const rejectInvites = async (companyId, inviteId) => {
    const res = await axios.put(`${dashboardAPIUrl}/api/company/${companyId}/invites/reject/${inviteId}`);

    return res.status === 204;
  };

  const getAllInvites = async () => {
    const res = await axios.get(`${dashboardAPIUrl}/api/user/invites`);

    return res.status === 200 ? res.data : [];
  };

  const updateProfile = async (userId, payload) => {
    const res = await axios.put(`${dashboardAPIUrl}/api/user/${userId}/profile`, payload);

    if (res.status === 204) {
      return true;
    }

    return false;
  };

  const getUserProfileData = async () => {
    const res = await axios.get(`${dashboardAPIUrl}/api/user/${user['https://graffle.io/graffle_id']}/profile`);

    if (res.status === 200) {
      return res.data;
    }

    return false;
  };

  return (
    <AppDataContext.Provider
      value={{
        ...state,
        acceptInvite,
        cancelInvite,
        craeteNewCompany,
        createNewProject,
        createFlowHealthSignalStream,
        createInvite,
        createNewIndexer,
        createNewEventIndexer,
        dashboardUpdateCurrentSelectedProject,
        deleteCompany,
        deleteProject,
        deleteIndexer,
        getAllInvites,
        getRole,
        getProjects,
        getUserProfileData,
        getWebHookUsage,
        getEventIndexerUsage,
        getLiveStreamUsage,
        getWebhookLogs,
        getWebhookLogDetails,
        getCanceledInvites,
        getCompanyMembers,
        getEventSettings,
        getPendingInvites,
        getProjectDetails,
        getSearchAPIUsage,
        replayEvents,
        rejectInvites,
        removeMemberFromCompany,
        makeMemberAdmin,
        revokeMemberAdmin,
        refreshProjects,
        refreshCompanies,
        removeUserFromCompany,
        updateCurrentCompany,
        updateCompanyName,
        updateExistingEventIndexer,
        updateProjectDetails,
        updateProfile,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };
