import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/dashboard" replace /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'dashboard', element: <Home /> },

        {
          path: 'projects',
          children: [
            { element: <Projects />, index: true },
            { path: ':projectid', element: <ProjectDetails /> },
          ],
        },

        {
          path: 'company',
          element: (
            <RoleBasedGuard>
              <CompanyManagement />
            </RoleBasedGuard>
          ),
        },
        { path: 'analytics', element: <AnalyticsPage /> },
        { path: 'events', element: <EventsPage /> },
        {
          path: 'webhooks',
          children: [
            { element: <WebhookLogsPage />, index: true },
            { path: 'details/', element: <WebhookLogDetail /> },
            { path: 'replay', element: <Replay /> },
          ],
        },
        { path: 'profile', element: <UserProfile /> },

        { path: 'form', element: <CadenceForm /> },

        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '*',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const Home = Loadable(lazy(() => import('../pages/dashboard/Home')));
const Projects = Loadable(lazy(() => import('../pages/Projects')));
const CadenceForm = Loadable(lazy(() => import('../pages/CadenceForm')));
const EventsPage = Loadable(lazy(() => import('../pages/Events')));
const WebhookLogsPage = Loadable(lazy(() => import('../pages/WebhookLogs')));
const WebhookLogDetail = Loadable(lazy(() => import('../pages/WebhookLogDetail')));
const Replay = Loadable(lazy(() => import('../pages/replay')));
const UserProfile = Loadable(lazy(() => import('../pages/UserProfile')));
const AnalyticsPage = Loadable(lazy(() => import('../pages/AnalyticsPage')));

const ProjectDetails = Loadable(lazy(() => import('../pages/ProjectDetails')));
const CompanyManagement = Loadable(lazy(() => import('../pages/CompanyManagement')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
