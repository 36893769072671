// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography, Typograpy, Stack } from '@mui/material';

import LogoLarge from './LogoLarge';

import useSettings from '../hooks/useSettings';

const ErrorContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  //   backgroundColor: 'red',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
const RootStyle = styled('div')(({ theme }) => ({
  width: 600,

  position: 'absolute',

  backgroundColor: theme.palette.background.default,
  borderColor: '#ff6600',
  borderStyle: 'solid',
  borderWidth: 4,
  borderRadius: 20,
  padding: 30,
}));

const AppLoadErrorMessage = () => {
  const { themeMode } = useSettings();

  return (
    <ErrorContainer>
      <RootStyle>
        <Stack direction="row" alignItems={'center'} justifyContent="space-between">
          <Typography variant="h4">Graffle Management Dashboard</Typography>
          <LogoLarge />
        </Stack>
        <Box sx={{ backgroundColor: 'transparent', padding: '0px' }}>
          <Typography sx={{ marginTop: '20px' }} variant="subtitle1">
            An error occurred while attempting to retrieve the data for your companies. Please refresh the page and try
            again. If this problem persists, please contact us on the Graffle Discord server.
          </Typography>
        </Box>
      </RootStyle>
    </ErrorContainer>
  );
};

export default AppLoadErrorMessage;
