import { useEffect, useState } from 'react';

// routes
import Router from './routes';

// Contexts
import useAppData from './hooks/useAppData';
// theme
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

import LoadingScreen from './components/LoadingScreen';
import AppLoadErrorMessage from './components/AppLoadErrorMessage';

// ----------------------------------------------------------------------

if (localStorage.getItem('env') === null) {
  localStorage.setItem('env', 'testnet');
}

export default function App() {
  const { loading, initError } = useAppData();

  // useEffect(() => {}, [loading])

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              {initError && <AppLoadErrorMessage />}
              {!loading && !initError && (
                <>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <Settings />
                  <ScrollToTop />
                  <Router />
                </>
              )}
              {loading && !initError && <LoadingScreen isDashboard={false} />}
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
