import { useState, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';

// @mui
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';

//
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

// utils
import useAuth from '../../../../hooks/useAuth';
import useAppData from '../../../../hooks/useAppData';

const CreateCompanyDialog = ({ showDialog, hideButton, closeCallback }) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(showDialog || false);
  const { enqueueSnackbar } = useSnackbar();
  const { dashboardAPIUrl } = useSelector((state) => state.enviroment);
  const formRef = useRef();
  const { refreshCompanies, updateCurrentCompany, companies, craeteNewCompany } = useAppData();
  const [newCompanyId, setNewCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (closeCallback) closeCallback();
    setOpen(false);
    refreshCompanies();
  };

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    if (newCompanyId) {
      updateCurrentCompany(newCompanyId);

      if (closeCallback) closeCallback();
    }
  }, [companies]);

  return (
    <div>
      {!hideButton && (
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create Company
        </Button>
      )}

      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>Create</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the name of the new company you want to create.</DialogContentText>
          <Formik
            innerRef={formRef}
            initialValues={{
              name: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
            })}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
              try {
                const result = await craeteNewCompany(values.name);
                if (result) {
                  setSubmitting(false);
                  enqueueSnackbar('New company created', { variant: 'success' });
                  setNewCompanyId((prev) => result.data);
                  handleClose();
                }
              } catch (e) {
                console.error(e);
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    if (e.target.value.length <= 60) {
                      handleChange(e);
                      setCompanyName(e.target.value);
                    }
                  }}
                  type="text"
                  margin="dense"
                  value={values.name}
                  variant="outlined"
                  label="Company Name"
                />
              </form>
            )}
          </Formik>
          {`${companyName.length}/60`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateCompanyDialog;
