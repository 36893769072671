import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Select, MenuItem, Typography, FormControl } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useAppData from '../../../hooks/useAppData';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import {
  DASHBOARD_NAVBAR_WIDTH,
  DASHBOARD_HEADER_MOBILE,
  DASHBOARD_HEADER_DESKTOP,
  DASHBOARD_NAVBAR_COLLAPSE_WIDTH,
} from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import CreateCompanyDialog from '../../../sections/@dashboard/general/app/CreateCompanyDialog';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})(({ isCollapse, theme }) => ({
  boxShadow: 'none',
  ...cssStyles(theme).bgBlur(),
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${DASHBOARD_NAVBAR_COLLAPSE_WIDTH}px)`,
    }),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: DASHBOARD_HEADER_MOBILE,
  transition: theme.transitions.create('min-height', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
    minHeight: DASHBOARD_HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardHeader({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const { companies, currentCompany, updateCurrentCompany } = useAppData();
  const [showNewCompanyDialog, setShowNewCompanyDialog] = useState(false);
  const [createCompanyDialog, setCreateCompanyDialog] = useState(null);

  const isOffset = useOffSetTop(DASHBOARD_HEADER_DESKTOP);

  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {createCompanyDialog}

      <RootStyle isCollapse={isCollapse}>
        <ToolbarStyle
          sx={{
            ...(isOffset && {
              minHeight: { md: DASHBOARD_HEADER_DESKTOP - 16 },
            }),
          }}
        >
          {!isDesktop && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          {/* <Searchbar /> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ mr: 2, flexGrow: 0 }}>
            <FormControl variant="standard">
              <Select
                sx={{ width: 500 }}
                value={currentCompany}
                onChange={(e) => {
                  if (e.target.value === '-1') {
                    // setShowNewCompanyDialog(true);
                    setCreateCompanyDialog(
                      <CreateCompanyDialog
                        hideButton
                        showDialog
                        closeCallback={() => {
                          setCreateCompanyDialog(null);
                        }}
                      />
                    );
                  } else {
                    window.location.href = `${window.location.origin}${window.location.pathname}`;
                    updateCurrentCompany(e.target.value);
                  }
                }}
              >
                {companies &&
                  companies.map((company, index) => {
                    return (
                      <MenuItem
                        key={index}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                        value={company.company_id}
                      >
                        <Typography sx={{ flexGrow: 0 }} variant="body1">
                          {`${company.company_name}`}
                        </Typography>
                        <Typography sx={{ flexGrow: 0 }} variant="caption">
                          {company.company_name.length > 30
                            ? `...${company.company_id.substring(
                                company.company_id.length / 2 + (company.company_name.length - 30)
                              )}`
                            : company.company_id}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                <MenuItem value="-1">Create New Company</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </>
  );
}
