import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, FormControlLabel, Link, Typography, Switch } from '@mui/material';

// Redux
import { useDispatch } from 'react-redux';
import { switchMode } from '../../../redux/slices/enviroment';

// hooks
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [checkedState, setCheckedState] = useState(localStorage.getItem('env') || 'testnet');

  const handleChange = () => {
    if (checkedState === 'testnet') {
      setCheckedState('mainnet');
      dispatch(switchMode());
      localStorage.setItem('env', 'mainnet');
      // window.location.reload();
    } else {
      setCheckedState('testnet');

      dispatch(switchMode());
      localStorage.setItem('env', 'testnet');
      // window.location.reload();
    }
    window.location.assign(`${window.location.origin}/${window.location.pathname.split('/')[1]}`);
  };

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar src={user.picture} alt="Rayan Moran" />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {user.role}
          </Typography>

          <FormControlLabel
            control={<Switch checked={checkedState === 'mainnet'} onChange={handleChange} name="checkedA" />}
            label={checkedState === 'testnet' ? 'On Testnet' : 'On Mainnet'}
          />
        </Box>
      </RootStyle>
    </Link>
  );
}
