import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

// hooks
import useAppData from '../hooks/useAppData';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const { companies, currentCompany, getRole } = useAppData();

  if (getRole() < 2) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
